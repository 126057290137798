.login {
  min-height: 100vh;
}

.custom-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bg-image {
  background-image: url('https://gerapromos.com.br/wp-content/uploads/2024/07/Design-sem-nome-1-1.gif');
  background-size: cover;
  background-position: center;
}

.login-heading {
  font-weight: 300;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  color: white;
  border-radius: 10px;
  background-color: #021F6A;
  padding: 0.75rem 1rem;
}

.invalid {
  border-color: red !important;
}